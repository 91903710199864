import { createAsyncThunk } from "@reduxjs/toolkit";
import { GroupEnum, User, CreateTurnForm, QueueManagement } from "../Interfaces/UserInterface";
import ApiService from "./apiService";

// const isLocal =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local";
const isLocal = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;

export const usersURL = `${isLocal ? "/users" : ""}/users/`;
export const findUsersURL = `${usersURL}find-users/`;
export const schoolAdminURL = `${usersURL}school-admins/`;
export const blockUserURL = `${usersURL}blockUser/`;
export const studentUrl = `${usersURL}student`;

export async function getAllSchoolAdmins(): Promise<User[]> {
  const result: any = await ApiService.get<User[]>(schoolAdminURL);
  if (result.success) {
    return result.data;
  }
  throw new Error(result.message);
}

export const createUser = createAsyncThunk(
  "user/createUser",
  async (user: User) => {
    const result: any = await ApiService.post<User>(usersURL, user);
    if (result.success) {
      return result.data;
    }
    if (result.statusCode === 413) {
      result.message =
        "Profile picture exceeds the maximum size allowed (5 Mb)";
    }
    throw new Error(result.message);
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (user: User) => {
    const result: any = await ApiService.put<User>(usersURL, user);
    if (result.success) {
      return result.data;
    }
    throw new Error(result.message);
  }
);

export const blockUser = createAsyncThunk(
  "user/blockUser",
  async (user: User) => {
    const result: any = await ApiService.put<User>(blockUserURL, user);
    if (result.success) {
      return result.data;
    }
    throw new Error(result.message);
  }
);

export const fetchAllSchoolAdminsBySchoolId = createAsyncThunk(
  "user/fetchAllSchoolAdminsBySchoolId",
  async (school_id: number) => {
    const result: any = await ApiService.get<User[]>(
      `${schoolAdminURL}school/${school_id}`
    );
    if (result.success) {
      return result.data;
    }
    throw new Error(result.message);
  }
);

export const createSchoolAdminUser = createAsyncThunk(
  "user/createSchoolAdminUser",
  async (user: User) => {
    const result: any = await ApiService.post<User>(schoolAdminURL, user);
    if (result.success) {
      return result.data;
    }
    if (result.statusCode === 413) {
      result.message =
        "Profile picture exceeds the maximum size allowed (5 Mb)";
    }
    throw new Error(result.message);
  }
);

export const createStudentUser = createAsyncThunk(
  "user/createStudentUser",
  async (user: User): Promise<User> => {
    const result: any = await ApiService.post<User>(studentUrl, user);
    if (result.success) {
      return result.data;
    }
    if (result.statusCode === 413) {
      result.message =
        "Profile picture exceeds the maximum size allowed (5 Mb)";
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export async function getProfilePicture(key: string) {
  if (key !== undefined && key !== null) {
    const response = await ApiService.get(
      `${usersURL}profile-picture/${key || ""}`
    );
    return "data:image/jpeg;base64," + response.data;
  }
}

export const fetchProfilePicture = createAsyncThunk(
  "user/fetchProfilePicture",
  async (user: User) => {
    const profilePicture = await getProfilePicture(`${user.profile_picture}`);
    return { ...user, profile_picture_encoded: profilePicture };
  }
);

export const getUserByUsername = createAsyncThunk(
  "user/getUserByUsername",
  async (username: string) => {
    if (username === undefined || username === "") return;

    const result: any = await ApiService.get(`${usersURL}${username}`);
    if (result?.success) {
      if (result.data.group !== GroupEnum.SysAdmin) {
        result.data.profile_picture_encoded = await getProfilePicture(
          result.data.profile_picture
        );
      }
      return result.data;
    }
    throw new Error(result?.message);
  }
);

// export const getUserByStudentIdNumber = createAsyncThunk(
//   "user/getUserByStudentIdNumber",
//   async (student_id_number: string) => {
//     if (student_id_number === undefined || student_id_number === "") return;
//     const result: any = await ApiService.get(
//       `${usersURL}student_id_number/${student_id_number}`
//     );
//     if (result?.success) {
//       return result.data;
//     }
//     throw new Error(result?.message);
//   }
// );

export async function getUserByStudentIdNumber(
  student_id_number: string
): Promise<User> {
  const result: any = await ApiService.get(
    `${usersURL}student_id_number/${student_id_number}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getUserByUsernameDashboard(
  username?: string
): Promise<User> {
  const result: any = await ApiService.get<User>(`${usersURL}${username}`);
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export const getUsersByGroupAndSchoolID = createAsyncThunk(
  "user/getUsersByGroupAndSchoolID",
  async ({
    groupName,
    schoolID,
  }: {
    groupName: GroupEnum;
    schoolID: number;
  }) => {
    const result: any = await ApiService.get(
      `${findUsersURL}${schoolID}/${groupName}`
    );

    if (result?.success) {
      if (result.data.group !== GroupEnum.SysAdmin) {
        result.data.profile_picture_encoded = await getProfilePicture(
          result.data.profile_picture
        );
      }
      return {group:groupName, data:result.data};
    }

    throw new Error(result?.message);
  }
);

export const getUsersByPreventMeeting = createAsyncThunk(
  "user/getUsersByPreventMeeting",
  async ({
    studentId,
  }: {
    studentId: number;
  }) => {
    const result: any = await ApiService.get(
      `${usersURL}student/${studentId}`
    );

    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updatePreventMeetingByStudent = createAsyncThunk(
  "user/updatePreventMeetingByStudent",
  async ({
    studentId,
    students,
  }: {
    studentId: number,
    students : number[];
  }) => {
    const result: any = await ApiService.put(
      `${usersURL}student/${studentId}`,
      students
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createQueueManagerByStudent = createAsyncThunk(
  "user/createQueueManagerByStudent",
  async (turn : CreateTurnForm) => {
    const result: any = await ApiService.post<number>(
      `${usersURL}studentQueueManager`,
      turn
    );

    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteQueueManagerByStudent = createAsyncThunk(
  "user/deleteQueueManagerByStudent",
  async (studentId : string) => {
    const result: any = await ApiService.delete<number>(
      `${usersURL}studentQueueManager/${studentId}`
    );

    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userName: string) => {
    const result: any = await ApiService.delete<User>(`${usersURL}${userName}`);
    if (result.success) {
      return result.data;
    }
    throw new Error(result.message);
  }
);

export const fetchManagersBySchoolId = createAsyncThunk(
  "user/fetchManagersBySchoolId",
  async (school_id: number) => {
    const result: any = await ApiService.get(
      `${usersURL}managers/school/${school_id}`
    );

    if (result?.success) {
      return result.data;
    }

    throw new Error(result?.message);
  }
);

export const fetchStudentsByClassId = createAsyncThunk(
  "user/fetchStudentsByClassId",
  async (classId: number) => {
    const result: any = await ApiService.get(
      `${usersURL}students/class/${classId}`
    );

    if (result?.success) {
      return result.data;
    }

    throw new Error(result?.message);
  }
);

export const fetchStudentsBySchoolId = createAsyncThunk(
  "user/fetchStudentsBySchoolId",
  async (schoolId: number) => {
    const result: any = await ApiService.get(
      `${usersURL}students/school/${schoolId}`
    );

    if (result?.success) {
      return result.data;
    }

    throw new Error(result?.message);
  }
);

export const addStudentsToClass = createAsyncThunk(
  "user/addStudentsToClass",
  async ({
    class_id,
    studentIds,
  }: {
    class_id: number;
    studentIds: number[];
  }) => {
    const result: any = await ApiService.post<User[]>(
      `${usersURL}class/${class_id}`,
      { studentIds }
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const addStudentsToClassSchedule = createAsyncThunk(
  "user/addStudentsToClassSchedule",
  async ({
    classSchedule_id,
    studentIds,
  }: {
    classSchedule_id: number;
    studentIds: number[];
  }) => {
    const result: any = await ApiService.post<User[]>(
      `${usersURL}class/schedule/${classSchedule_id}`,
      { studentIds }
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const removeStudentsFromClass = createAsyncThunk(
  "user/removeStudentsFromClass",
  async ({ studentId, class_id }: { studentId: number; class_id: number }) => {
    const result: any = await ApiService.delete<User>(
      `${usersURL}student/${studentId}/class/${class_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const getUserByUsername3 = createAsyncThunk(
  "user/getUserByUsername",
  async (username: string) => {
    const result: any = await ApiService.get(`${usersURL}${username}`);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export async function getUserByStudentIdNumber2(
  student_id_number: string
): Promise<User> {
  const result: any = await ApiService.get(
    `${usersURL}student_id_number/${student_id_number}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getUserByUsername2(
  username?: string
) : Promise<User> {
  const result: any = await ApiService.get<User>(`${usersURL}${username}`);
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function createQueueManagerByUserName(
  turn : CreateTurnForm
) : Promise<number> {
  const result: any = await ApiService.post<number>(
    `${usersURL}studentQueueManager`,
    turn
  );

  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}


export async function getQueueManagerByUserName(
  studentId : string
) : Promise<QueueManagement> {
  const result: any = await ApiService.get<QueueManagement>(
    `${usersURL}studentQueueManager/${studentId}`
  );

  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function validateQueueManager(
  studentId : string
) : Promise<QueueManagement> {
  const result: any = await ApiService.get<boolean>(
    `${usersURL}validateQueueManager/${studentId}`
  );

  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function removeTurnFromQueue(
  studentId : string
) : Promise<string> {
  const result: any = await ApiService.delete<string>(
    `${usersURL}removeTurnFromQueue/${studentId}`
  );

  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}


export async function accessClassLink(
  userClassLink : any
) : Promise<string> {
  const result: any = await ApiService.post<string>(
    `${usersURL}classlink-access`, userClassLink
  );

  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function accessClassLinkOauth2(
  code : string
) : Promise<string> {
  const result: any = await ApiService.post<string>(
    `${usersURL}classlink-access-oauth2/${code}`,
  );

  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}