import {
  Box,
  CssBaseline,
  Grid,
  LinearProgress,
  AlertColor,
} from "@mui/material";
import { LayoutContainer } from "../Layout/layout.container";
import { CSSProperties } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  setCustomAlertDetails,
  setPageHeaderChildren,
  setPageSubtitle,
  setPageTitle,
  setShowCustomAlert,
} from "../store/slices/main";
import Html5QrcodePlugin from "../Services/Html5QrcodePlugin"
import {
  createAttendance,
  createAttendanceKioskPage,
} from "../Services/AttendanceService";
import { getUserByStudentIdNumber, getUserByUsername2, deleteQueueManagerByStudent } from "../Services/UsersService";
// import { getClassByTeacherUser } from "../Services/ClassService";
import {
  Attendance,
  StatusAttendanceEnum,
} from "../Interfaces/AttendanceInterface";
import { RootState } from "../store";
import { AttendanceListClassComponent } from "../Components/AttendanceList/AttendanceListClassComponent";
import { getCurrentClassByTeacherId, getAttendanceClassByPeriodAndDayType } from "../Services/ClassService";
import { completeHallPassLocationRequest } from "../Services/HallPassService";
import { Class } from "../Interfaces/ClassInterface";
import { User } from "../Interfaces/UserInterface";
import { setErrorMessage, setSuccessMessage } from "../store/slices/user.slice";
import { CustomAlert } from "../Components";
import moment from "moment";
import { setSelectedUser } from "../store/slices/main";

const styles = {
  searchContainer: {
    background: "#3C404E",
    padding: "10px",
    height: 50,
    width: "100%",
    borderRadius: "8px 8px 0px 0px ",
  },
  listContainer: {
    background: "#3C404E",
    borderRadius: "5px 5px 0 0",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    overflow: "auto",
    textAlign: "center",
    minHeight: "50vh",
    maxHeight: "50vh",
    zIndex: 3,
  } as CSSProperties,
};

const KioskTeacherPage = () => {
  const dispatch = useDispatch();

  const { savingIndicator, errorMessage, successMessage } = useSelector(
    (state: RootState) => state.attendance
  );

  const { currentStudentAttendace } = useSelector(
    (state: RootState) => state.user
  );

  const { currentSchool } = useSelector((state: RootState) => state.main);

  const [title, setTitle] = useState<string>("");
  // const [singleStudentId, setSingleStudentId] = useState("AD-8899-7899");
  const [singleStudentId, setSingleStudentId] = useState("");
  const [lastReloadAux, setLastReloadAux] = useState<Date>(new Date());
  const [currentClass, setCurrenClass] = useState<Class | null>(null);
  const [loadingClass, setLoadinClass] = useState<boolean>(false);
  const [studentUser, setStudentUser] = useState<User | null>(null);
  const [savingAttendance, setSavingAttendance] = useState<boolean>(false);
  const [studentOnLocDataClass, setStudentOnLocDataClass] = useState<any | null>(null);
  
  const navigate = useNavigate();

  const onNewScanResult = (decodedText : any, decodedResult : any) => {
    console.log(decodedText)
    setSingleStudentId(decodedText);
  };
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    // getClassByTeacherUser(4)
    dispatch(setPageTitle(currentClass?.name));
    dispatch(setPageHeaderChildren(null));

    if (currentClass !== null) {
      dispatch(
        setPageSubtitle(`
        <div style="font-size: 12px; margin= 5px;">
          <span style="color: #FFFFFFB2;"> Room </span> ${
            currentClass?.room?.name
          } · 
          <span style="color: #FFFFFFB2;"> Day Type </span> ${
            currentClass?.day_type?.name
          } ·
          <span style="color: #FFFFFFB2;"> Period </span> ${
            currentClass?.period?.name
          } <br>
          <span style="color: #FFFFFFB2;"> · ${moment().format(
            "ddd MMM Do"
          )} · </span> 
        </div>
      `)
      );
      (async () => {
        try {
          const studentOnLocData = await getAttendanceClassByPeriodAndDayType(currentClass?.period_id, currentClass?.day_type_id);
          setStudentOnLocDataClass(studentOnLocData);
        } catch (error) {
          console.log(error);
        }
      })();
    }
    forceLastReloadAttendanceList();
  }, [currentClass]);

  useEffect(() => {
    if (singleStudentId !== "") {
      setSavingAttendance(true);
      getUserByStudentId(singleStudentId);

    }
  }, [singleStudentId]);

  useEffect(() => {
    if (studentUser) {
      dispatch(deleteQueueManagerByStudent(studentUser?.user_name))
      console.log('*** student user ***', studentUser);
      /**Set current client date */
      const studentHallPass = studentOnLocDataClass[0]?.StudentsOnLocations;
      console.log('*** student hall pass ***', studentHallPass);
      console.log('*** currentClass ***', currentClass)
      const hasActiveHallPass = studentHallPass.some((item: any) => {
        return item.student_id === studentUser.id
          // && item.current_class_id === Number(currentClass?.id)
          && studentUser.sentToHallPass === true;
          // && item.status === 'InProgress';
      });
      const sentToNextClass = studentHallPass.some((item: any) => {
        return item.student_id === studentUser.id
        && studentUser.sentToNextClass === true;
      });
      if (hasActiveHallPass) {
        console.log(hasActiveHallPass);
        hallPassRequestComplete(studentUser?.user_name)
  
      // }else if (hasCompleteHallPass) {
      //   console.log(hasCompleteHallPass);
      //   showAlert("Hall Pass Request Already Completed", "success");
      //   setTimeout(() => {
      //     handleCloseAlert();
      //   }, 2000);
      //   console.log(studentUser);
      //   setSingleStudentId("");
      //   forceLastReloadAttendanceList();
      //   setSavingAttendance(false);
      } else {
        const currDate = moment(new Date())
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0);
        setSavingAttendance(true);
        let attendance: Attendance = {
          user_id: studentUser.id,
          class_id: Number(currentClass?.id),
          day_type_id: currentClass?.day_type_id,
          statusAttendance: StatusAttendanceEnum.OnTime,
        };
        // dispatch(createAttendance(attendance));
        createAttendance(attendance);
      }
    }
  }, [studentUser]);

  useEffect(() => {
    loadCurrenClass();
    console.log(user.id);
  }, [user.id]);

  function getUserByStudentId(singleStudentId: string) {
    const studentUser = async () => {
      return await getUserByStudentIdNumber(singleStudentId);
    };
    studentUser()
      .then((result: User) => {
        setSavingAttendance(false);
      if (result) setStudentUser(result);
      })
      .catch((err) => {
        setSavingAttendance(false);
        setStudentUser(null);
        showAlert(err.message, "error");
        setTimeout(() => {
          handleCloseAlert();
        }, 2000);
      });
  }


  
  function selectStudent(user: any) {
    dispatch(setSelectedUser(user));
    navigate("/KioskStudentHallPassLocations");
  }



  function createAttendance(attendance: Attendance) {
    const createAttendance = async () => {
      return await createAttendanceKioskPage(attendance);
    };
    console.log(createAttendance);
    createAttendance()
      .then((result: Class) => {
        showAlert("Attendance successfully saved", "success");
        setTimeout(() => {
          handleCloseAlert();
        }, 2000);
        console.log(result);
        setSingleStudentId("");
        forceLastReloadAttendanceList();
        setSavingAttendance(false);
      })
      .catch((err) => {
        selectStudent(studentUser);
        // showAlert(err.message, "error");
        // setTimeout(() => {
        //   handleCloseAlert();
        // }, 2000);
        // console.log(err);
        setSingleStudentId("");
        forceLastReloadAttendanceList();
        setSavingAttendance(false);
      });
  }

  async function hallPassRequestComplete(username: string): Promise<void> {
    const result: any = await dispatch(completeHallPassLocationRequest(username));
    if (result?.payload) {
      showAlert("Hall Pass Request completed successfully", "success");
      setTimeout(() => {
        handleCloseAlert();
      }, 2000);
      console.log(result);
      setSingleStudentId("");
      forceLastReloadAttendanceList();
      setSavingAttendance(false);
    }
  }

  function loadCurrenClass() {
    setLoadinClass(true);
    if (user.id) {
      /**Set current client date */
      const currDate = moment(new Date())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      const fetchData = async () => {
        return await getCurrentClassByTeacherId(user.id, currentSchool.id);
      };
      fetchData()
        .then((result: Class) => {
          console.log(result);
          if (Object.keys(result).length === 0) {
            setCurrenClass(null);
          } else {
            setCurrenClass(result);
          }
          setLoadinClass(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadinClass(false);
          setCurrenClass(null);
        });
    }
  }

  function forceAttendance() {
    setSingleStudentId("AD-8899-7899");
    console.log("attendance");
  }

  function forceLastReloadAttendanceList() {
    console.log("reload");
    setLastReloadAux(new Date());
  }

  function showAlert(message: string, severity: AlertColor) {
    const alert: AlertState = {
      message,
      severity,
    };
    dispatch(setCustomAlertDetails(alert));
    dispatch(setShowCustomAlert(true));
  }

  function handleCloseAlert() {
    dispatch(setShowCustomAlert(false));
    dispatch(setSuccessMessage(""));
    dispatch(setErrorMessage(""));
  }

  return (
    <>
      <CssBaseline />
      {loadingClass ? (
        <LinearProgress />
      ) : (
        <LayoutContainer isRaised={true}>
          {currentClass == null || Object.keys(currentClass).length === 0 ? (
            <h2>The teacher has not assigned classes</h2>
          ) : (
            <>
              <Box style={styles.listContainer} flexDirection="column">
                <Grid
                  sx={{ height: "50vh" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="camera-container-ui">
                    <div className="camera-container">
                      {savingAttendance ? (
                        <LinearProgress />
                      ) : (
                        <Html5QrcodePlugin
                            fps={10}
                            qrbox={250}
                            disableFlip={false}
                            qrCodeSuccessCallback={onNewScanResult}
                        />
                        // <QrReader
                        //         onResult={(result: any, error) => {
                        //           if (result) {
                        //             showAlert(result, "success");
                        //             setTimeout(() => {
                        //               handleCloseAlert();
                        //             }, 2000);
                        //           }


                        //           if (error) {
                        //             console.info(error);
                        //           }
                        //         } } constraints={{}}                          // style={{ width: "100%" }}
                        //   // delay={300}
                        // />


                      )}
                    </div>
                  </div>
                </Grid>
              </Box>

              <CustomAlert handleClose={handleCloseAlert} />

              <AttendanceListClassComponent
                classId={currentClass?.id}
                lastReload={lastReloadAux}
              ></AttendanceListClassComponent>
            </>
          )}
        </LayoutContainer>
      )}
    </>
  );
};

export default KioskTeacherPage;
