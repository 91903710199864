import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { GroupEnum, User } from "../../Interfaces/UserInterface";
import {
  createSchoolAdminUser,
  fetchAllSchoolAdminsBySchoolId,
  fetchProfilePicture,
  getAllSchoolAdmins,
  getUserByUsername,
  createUser,
  updateUser,
  getUsersByGroupAndSchoolID,
  deleteUser,
  createStudentUser,
  fetchManagersBySchoolId,
  fetchStudentsByClassId,
  fetchStudentsBySchoolId,
  addStudentsToClass,
  addStudentsToClassSchedule,
  removeStudentsFromClass,
  getUserByStudentIdNumber,
  getUsersByPreventMeeting,
  updatePreventMeetingByStudent,
  createQueueManagerByStudent,
  deleteQueueManagerByStudent
} from "../../Services/UsersService";

export const fetchSchoolAdminUsers = createAsyncThunk(
  "user/fetchSchoolAdminUsers",
  async () => await getAllSchoolAdmins()
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    schoolAdmins: [] as User[],
    initialSchoolAdmins: [] as User[],
    loadingIndicator: false,
    savingIndicator: false,
    errorMessage: "",
    successMessage: "",
    user: {} as User,
    teachers: [] as User[],
    initialTeachers: [] as User[],
    selectedUser: {} as User,
    students: [] as User[],
    studentsInClass: [] as User[],
    initialStudents: [] as User[],
    managers: [] as User[],
    currentStudentAttendace: {} as User,
    staffUsers: [] as User[],
    initialStaffUsers: [] as User[],
    usersByPreventMeeting: [] as number[],
    turnQueue: -1 as number,
  },

  reducers: {
    setTurnQueue: (state, { payload }) => {
      state.turnQueue = payload;
   },
    filterSchoolAdmins: (state, { payload }) => {
      if (payload !== "") {
        const results = state.initialSchoolAdmins.filter((user) => {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(payload.toLowerCase());
        });
        state.schoolAdmins = results;
      } else {
        state.schoolAdmins = state.initialSchoolAdmins;
      }
    },
    filterTeachers: (state, { payload }) => {
      if (payload !== "") {
        const results = state.initialTeachers.filter((user) => {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(payload.toLowerCase());
        });
        state.teachers = results;
      } else {
        state.teachers = state.initialTeachers;
      }
    },
    filterStudents: (state, { payload }) => {
      if (payload !== "") {
        const results = state.initialStudents.filter((user) => {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(payload.toLowerCase());
        });
        state.students = results;
      } else {
        state.students = state.initialStudents;
      }
    },
    filterStaffUsers: (state, { payload }) => {
      if (payload !== "") {
        const results = state.initialStaffUsers.filter((user) => {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(payload.toLowerCase());
        });
        state.staffUsers = results;
      } else {
        state.staffUsers = state.initialStaffUsers;
      }
    },
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setSelectedUserStore: (state, { payload }) => {
      state.selectedUser = payload;
    },
    setLogOut: (state, { payload }) => {
      state.user = {} as User;
      state.schoolAdmins = [] as User[];
      state.initialSchoolAdmins = [] as User[];
      state.teachers = [] as User[];
      state.initialTeachers = [] as User[];
      state.staffUsers = [] as User[];
      state.initialStaffUsers= [] as User[];
    },
    resetStudentsInClass: (state) => {
      state.studentsInClass = [] as User[];
    },
  },
  extraReducers: (builder) => {
    //fetch
    /* #region  [fetchSchoolAdminUsers] */
    builder.addCase(fetchSchoolAdminUsers.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(fetchSchoolAdminUsers.fulfilled, (state, { payload }) => {
        state.schoolAdmins = payload;
        state.initialSchoolAdmins = payload;
        state.loadingIndicator = false;
      }),
      builder.addCase(fetchSchoolAdminUsers.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  fetch all school admins by school id */
    builder.addCase(fetchAllSchoolAdminsBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchAllSchoolAdminsBySchoolId.fulfilled,
        (state, { payload }) => {
          state.schoolAdmins = payload;
          state.initialSchoolAdmins = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(
        fetchAllSchoolAdminsBySchoolId.rejected,
        (state, { error }) => {
          state.errorMessage = `${error.message}`;
          state.loadingIndicator = false;
        }
      );
    /* #endregion */

    builder.addCase(fetchProfilePicture.fulfilled, (state, { payload }) => {
      const group = payload.group as GroupEnum;

      if (group === GroupEnum.SchoolAdmin) {
        let index = state.initialSchoolAdmins?.findIndex(
          (s) => s.id === payload.id
        );

        if (index > -1) {
          state.initialSchoolAdmins[index] = state.schoolAdmins[index] =
            payload;
        }
      } else if (group === GroupEnum.Teacher) {
        const teacherIndex = state.initialTeachers?.findIndex(
          (s) => s.id === payload.id
        );
        if (teacherIndex > -1) {
          state.initialTeachers[teacherIndex] = state.teachers[teacherIndex] =
            payload;
        }
      }
      else if (group === GroupEnum.Student) {
        const studentIndex = state.initialStudents?.findIndex(
          (s) => s.id === payload.id
        );
        if (studentIndex > -1) {
          state.initialStudents[studentIndex] = state.students[studentIndex] =
            payload;
        }
      }
      else if (group === GroupEnum.Staff) {
        const staffIndex = state.initialStaffUsers?.findIndex(
          (s) => s.id === payload.id
        );
        if (staffIndex > -1) {
          state.initialStaffUsers[staffIndex] = state.staffUsers[staffIndex] =
            payload;
        }
      }
    });

    /* #region [getUserByUsername] */
    builder.addCase(getUserByUsername.fulfilled, (state, { payload }) => {
      if (payload?.schools && payload.schools.length > 0) {
        const schools = payload.schools.map((school: any) => school?.school);
        payload.schools = schools;
        state.user = payload;
        state.loadingIndicator = false;
      }
    }),
      builder.addCase(getUserByUsername.pending, (state) => {
        state.loadingIndicator = true;
      });
    /* #endregion */

    /* #region [getUserByStudentIdNumber] */
    // builder.addCase(getUserByStudentIdNumber.fulfilled, (state, { payload }) => {
    //   state.currentStudentAttendace = payload;
    //   state.loadingIndicator = false;
    // }),
    //   builder.addCase(getUserByStudentIdNumber.pending, (state) => {
    //     state.loadingIndicator = true;
    //   });
    /* #endregion */

    /* #region  Get Users by Type [Teachers] */
    builder.addCase(getUsersByGroupAndSchoolID.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.loadingIndicator = true;
    }),
      builder.addCase(
        getUsersByGroupAndSchoolID.fulfilled,
        (state, { payload }) => {
          if (payload && payload?.data?.length > 0) {
            switch (payload.group) {
              case GroupEnum.Teacher:
                state.teachers = payload.data;
                state.initialTeachers = payload.data;
                break;
              case GroupEnum.Student:
                state.students = payload.data;
                state.initialStudents = payload.data;
                break;
              case GroupEnum.Staff:
                state.staffUsers = payload.data;
                state.initialStaffUsers = payload.data;
                break;
            }
          }else{
            switch (payload.group) {
              case GroupEnum.Teacher:
                state.teachers = [];
                state.initialTeachers = [];
                break;
              case GroupEnum.Student:
                state.students = [];
                state.initialStudents = [];
                break;
              case GroupEnum.Staff:
                state.staffUsers = [];
                state.initialStaffUsers = [];
                break;
            }
          }
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(
        getUsersByGroupAndSchoolID.rejected,
        (state, { error }) => {
          state.errorMessage = `${error.message}`;
          state.loadingIndicator = false;
        }
      );
    /* #endregion */

    //create

    /* #region  [createSchoolAdminUser] */
    builder.addCase(createSchoolAdminUser.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.savingIndicator = true;
    }),
      builder.addCase(createSchoolAdminUser.fulfilled, (state, { payload }) => {
        state.schoolAdmins.push(payload);
        state.initialSchoolAdmins.push(payload);
        state.successMessage = "School admin created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createSchoolAdminUser.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */

    /* #region  Create new user [Teachers] */
    builder.addCase(createUser.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.loadingIndicator = true;
    }),
      builder.addCase(createUser.fulfilled, (state, { payload }) => {
        const addedUser = payload as User;
        switch (addedUser.group) {
          case GroupEnum.Teacher:
            state.teachers.push(payload);
            break;
          case GroupEnum.SchoolAdmin:
            state.schoolAdmins.push(payload);
            break;
          case GroupEnum.Student:
            state.initialStudents.push(payload);
            state.students.push(payload);
            break;
            case GroupEnum.Staff:
            state.initialStaffUsers.push(payload);
            state.staffUsers.push(payload);
            break;
        }

        state.successMessage = "New user created successfully";
        state.loadingIndicator = false;
      }),
      builder.addCase(createUser.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  Update User [Teachers, Students] */
    builder.addCase(updateUser.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.loadingIndicator = true;
    }),
      builder.addCase(updateUser.fulfilled, (state, { payload }) => {
        const updatedUser = payload as User;
        const { usersState, initialUsersState } = selectUsersByGroup(
          state,
          updatedUser
        );

        const index = initialUsersState?.findIndex(
          (s) => s.id === updatedUser.id
        );
        if (index > -1) {
          initialUsersState[index] = usersState[index] = payload;
        }
        state.successMessage = "User updated successfully";
        state.loadingIndicator = false;
      }),
      builder.addCase(updateUser.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  Delete User [Teachers] */

    builder.addCase(deleteUser.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
        const { usersState, initialUsersState } = selectUsersByGroup(
          state,
          payload as User
        );
        let index = usersState.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          usersState.splice(index, 1);
        }
        index = initialUsersState.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          initialUsersState.splice(index, 1);
        }

        state.successMessage = "User removed successfully";
        state.loadingIndicator = false;
      }),
      builder.addCase(deleteUser.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });

    /* #endregion */

    /* #region  [createStudentUser] */
    builder.addCase(createStudentUser.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.savingIndicator = true;
    }),
      builder.addCase(createStudentUser.fulfilled, (state, { payload }) => {
        state.successMessage = "Student created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createStudentUser.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */

    /* #region  [fetchManagersBySchoolId] */
    builder.addCase(fetchManagersBySchoolId.pending, (state) => {
      state.errorMessage = state.successMessage = "";
    }),
      builder.addCase(
        fetchManagersBySchoolId.fulfilled,
        (state, { payload }) => {
          state.managers = payload;
        }
      );
    /* #endregion */

    /* #region  Fetch students by Class ID */
    builder.addCase(fetchStudentsByClassId.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchStudentsByClassId.fulfilled,
        (state, { payload }) => {
          state.studentsInClass = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchStudentsByClassId.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  Fetch students by Prevent Meeting */
    builder.addCase(getUsersByPreventMeeting.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.loadingIndicator = true;
    }),
      builder.addCase(
        getUsersByPreventMeeting.fulfilled,
        (state, { payload }) => {
          state.usersByPreventMeeting = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(getUsersByPreventMeeting.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    builder.addCase(updatePreventMeetingByStudent.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(updatePreventMeetingByStudent.fulfilled, (state, { payload }) => {
        state.successMessage = "Prevent meeting updated successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(updatePreventMeetingByStudent.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

      builder.addCase(createQueueManagerByStudent.pending, (state) => {
        state.savingIndicator = true;
      }),
      builder.addCase(
        createQueueManagerByStudent.fulfilled,
        (state, { payload }) => {
          state.turnQueue = payload;
          state.savingIndicator = false;
        }
      ),
      builder.addCase(createQueueManagerByStudent.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });


      builder.addCase(deleteQueueManagerByStudent.pending, (state) => {
        state.savingIndicator = true;
      }),
      builder.addCase(deleteQueueManagerByStudent.fulfilled, (state, { payload }) => {
        state.successMessage = "Turn delete";
        state.savingIndicator = false;
      }),
      builder.addCase(deleteQueueManagerByStudent.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });


    /* #region  Fetch students by School ID */
    builder.addCase(fetchStudentsBySchoolId.pending, (state) => {
      state.errorMessage = state.successMessage = "";
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchStudentsBySchoolId.fulfilled,
        (state, { payload }) => {
          state.students = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchStudentsBySchoolId.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.loadingIndicator = false;
      });
    /* #endregion */

    /* #region  Remove students from Class */
    builder.addCase(addStudentsToClass.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(addStudentsToClass.fulfilled, (state, { payload }) => {
        state.successMessage = "Roster updated successfully";
        const students = payload as User[];
        state.studentsInClass = students;
        state.savingIndicator = false;
      }),
      builder.addCase(addStudentsToClass.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */

    /* #region  Remove students from Class Schedule*/
    builder.addCase(addStudentsToClassSchedule.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(addStudentsToClassSchedule.fulfilled, (state, { payload }) => {
        state.successMessage = "Roster updated successfully";
        const students = payload as User[];
        state.studentsInClass = students;
        state.savingIndicator = false;
      }),
      builder.addCase(addStudentsToClassSchedule.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */

    /* #region  Remove students from Class */
    builder.addCase(removeStudentsFromClass.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(
        removeStudentsFromClass.fulfilled,
        (state, { payload }) => {
          state.successMessage = "Roster updated successfully";
          const deletedStudent = payload as User;
          state.studentsInClass = state.studentsInClass.filter(
            (student) => student.id !== deletedStudent.id
          );
          state.savingIndicator = false;
        }
      ),
      builder.addCase(removeStudentsFromClass.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
    /* #endregion */
  },
});

export default userSlice.reducer;
export const {
  filterTeachers,
  filterStudents,
  filterSchoolAdmins,
  filterStaffUsers,
  setTurnQueue,
  setSuccessMessage,
  setErrorMessage,
  setSelectedUserStore,
  setLogOut,
  resetStudentsInClass,
} = userSlice.actions;

function selectUsersByGroup(
  state: WritableDraft<{
    schoolAdmins: User[];
    initialSchoolAdmins: User[];
    initialTeachers: User[];
    teachers: User[];
    initialStudents: User[];
    students: User[];
    staffUsers: User[];
    initialStaffUsers: User[];
  }>,
  payload: User
) {
  let usersState: WritableDraft<User>[] = [];
  let initialUsersState: WritableDraft<User>[] = [];

  switch (payload.group) {
    case GroupEnum.Teacher:
      usersState = state.teachers;
      initialUsersState = state.initialTeachers;
      break;
    case GroupEnum.Student:
      usersState = state.students;
      initialUsersState = state.initialStudents;
      break;
    case GroupEnum.SchoolAdmin:
      usersState = state.schoolAdmins;
      initialUsersState = state.initialSchoolAdmins;
      break;
    case GroupEnum.Staff:
      usersState = state.staffUsers;
      initialUsersState = state.initialStaffUsers;
      break;
  }

  return { usersState, initialUsersState };
}
