import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateStudentOnLocationDto,
  HallPassLocation,
  StatusStudentOnLocationEnum,
  StudentOnLocation,
} from "../Interfaces/SchoolInterface";
import ApiService from "./apiService";

// const baseURL =
//   process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === "local";
const baseURL = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === "local"
    ? true
    : false
  : true;
const hallPassURL = `${
  baseURL ? "schools" : ""
}/schools/locations/hallPassrequest`;
const hallPassLocationsURL = `${baseURL ? "schools" : ""}/schools/locations`;

export const fetchHallPasses = createAsyncThunk(
  "schools/fetchHallPasses",
  async (school_id: number) => {
    const result: any = await ApiService.get(
      `${hallPassLocationsURL}/school/${school_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const createHallPassLocation = createAsyncThunk(
  "locations/createHallPassLocation",
  async (hallPassLocation: HallPassLocation) => {
    const result: any = await ApiService.post<HallPassLocation>(
      hallPassLocationsURL,
      hallPassLocation
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const updateHallPassLocation = createAsyncThunk(
  "locations/updateHallPassLocation",
  async (hallPassLocation: HallPassLocation) => {
    const result: any = await ApiService.put<HallPassLocation>(
      `${hallPassLocationsURL}/${hallPassLocation.id}`,
      hallPassLocation
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

export const deleteHallPassLocation = createAsyncThunk(
  "locations/deleteHallPassLocation",
  async (location_id: number) => {
    const result: any = await ApiService.delete<HallPassLocation>(
      `${hallPassLocationsURL}/${location_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  }
);

// Hall pass location request

export const createHallPassLocationRequest = createAsyncThunk(
  "hallPass/createHallPassLocationRequest",
  async (request: CreateStudentOnLocationDto) => {
    const result: any = await ApiService.post<StudentOnLocation>(
      hallPassURL,
      request
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const confirmHallPassLocationRequest = createAsyncThunk(
  "hallPass/confirmHallPassLocationRequest",
  async (request_id: number) => {
    const result: any = await ApiService.put<StudentOnLocation>(
      `${hallPassURL}/confirm/${request_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const cancelHallPassLocationRequest = createAsyncThunk(
  "hallPass/cancelHallPassLocationRequest",
  async (request_id: number) => {
    const result: any = await ApiService.put<StudentOnLocation>(
      `${hallPassURL}/cancel/${request_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const getRemainingTimeHallPassLocationRequest = createAsyncThunk(
  "hallPass/getRemainingTimeHallPassLocationRequest",
  async (request_id: number) => {
    const result: any = await ApiService.get<number>(
      `${hallPassURL}/remainingTime/${request_id}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);



export const completeHallPassLocationRequest = createAsyncThunk(
  "hallPass/completeHallPassLocationRequest",
  async (user_name: string) => {
    const result: any = await ApiService.put<StudentOnLocation>(
      `${hallPassURL}/complete/user/${user_name}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const fetchStudentsWithActivePassBySchoolId = createAsyncThunk(
  "hallPass/fetchStudentsWithActivePassBySchoolId",
  async (schoolId: number) => {
    const result: any = await ApiService.get<StudentOnLocation[]>(
      `${hallPassLocationsURL}/hallPass/${schoolId}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export const fetchStudentsWithCompletePassBySchoolId = createAsyncThunk(
  "hallPass/fetchStudentsWithCompletePassBySchoolId",
  async ({ school_id, hall_date = "" }: any) => {
    const result: any = await ApiService.get<StudentOnLocation[]>(
      `${hallPassLocationsURL}/hallPassComplete/${school_id}/${hall_date}`
    );
    if (result?.success) {
      return result.data;
    }
    throw new Error(result.response?.response?.data?.error);
  }
);

export async function checkStudentHallPassLocation(
  user_id: number
): Promise<StatusStudentOnLocationEnum | null> {
  const result: any = await ApiService.get<any>(
    `${hallPassURL}/user/${user_id}`
  );
  if (result?.success) {
    return result.data ? result.data : null;
  }
  throw new Error(result?.message);
}

export async function sentStudentToNextClass(user_id: number) {
  const result: any = await ApiService.post<any>(
    `${hallPassLocationsURL}/sentStudentToNextClass/${user_id}`
  );
  if (result?.success) {
    return result.data ? result.data : false;
  }
  throw new Error(result?.message);
}

export const scheduleHallPassCancellation = async (requestId: number) => {
  const result: any = await ApiService.post<any>(
    `${hallPassURL}/scheduleCancellation/${requestId}`
  );
  if (result?.success) {
    return result.data ? result.data : false;
  }
  throw new Error(result?.message);
};

export async function sentStudentToToHallPass(user_id: number) {
  const result: any = await ApiService.post<any>(
    `${hallPassLocationsURL}/sentStudentToToHallPass/${user_id}`
  );
  if (result?.success) {
    return result.data ? result.data : false;
  }
  throw new Error(result?.message);
}

export async function getCurrentHallPassRequestByStudentUserName(
  user_name: string
) {
  const result: any = await ApiService.post<any>(
    `${hallPassLocationsURL}/CurrentHallPassRequestByStudentUserName/${user_name}`
  );
  if (result?.success) {
    return result.data ? result.data : false;
  }
  throw new Error(result?.message);
}

export async function getLocationsByUserManager(
  user_name: string
): Promise<any> {
  const result: any = await ApiService.get<any>(
    `${hallPassLocationsURL}/locationsByManagerUser/${user_name}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function createHallPassKioskLocation(
  request: CreateStudentOnLocationDto
): Promise<any> {
  const result: any = await ApiService.post<CreateStudentOnLocationDto>(
    `${hallPassLocationsURL}/hallPassKioskRequest`,
    request
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result.response?.response?.data?.error);
}

export async function getStudentsByLocation(
  location_id: number | null
): Promise<any> {
  const result: any = await ApiService.get<any>(
    `${hallPassLocationsURL}/studentsByLocation/${location_id}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function getStudentsByLocationOnTheWay(
  location_id: number | null
): Promise<any> {
  const result: any = await ApiService.get<any>(
    `${hallPassLocationsURL}/studentsByLocationOnTheWay/${location_id}`
  );
  if (result?.success) {
    return result.data;
  }
  throw new Error(result?.message);
}

export async function logDeviceInformation(deviceInfo: any) {
  const logEndpoint = `${hallPassLocationsURL}/passlogs`;
  try {
    const result: any = await ApiService.post(logEndpoint, deviceInfo);
    if (result?.success) {
      return result.data;
    }
    throw new Error(result?.message);
  } catch (error) {
    // Handle the error appropriately
    console.error('Error logging device information:', error);
    throw error;
  }
}
