import {
  Box,
  Grid,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActiveHallPassesCard } from "../Components";
import { fetchStudentsWithActivePassBySchoolId } from "../Services/HallPassService";
import { AppDispatch, RootState } from "../store";
import Paper from "@mui/material/Paper";
import {
  getAverageNumberOfPasses,
  getClassRoomEfficiencyAverage,
  getTopPassLocationsStatistic,
  getWaitAverage,
  getWaitDailyAverage,
  getAveragePassDurationInMinutes,
} from "../Services/SemestersService";
import { getAbsentsBySchoolId } from "../Services/AttendanceService";
import { ChevronRightOutlined } from "@mui/icons-material";
import { useWebSocket } from "../Hooks/useWebSocket";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const styles = {
  cardTitle: {
    color: "#7AC142",
    fontSize: "36px",
    width: "100%",
    lineHeight: "0.5",
  },
  cardTitleDetail: {
    fontSize: "25px",
  },
  cardContent: {
    color: "rgba(255, 255, 255, 0.75)",
    margin: "0",
    width: "100%",
    lineHeight: "1.2",
    marginTop: "15px",
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#3C404E" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 120,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Loading = () => <>Loading ...</>;

const StatisticCard = ({
  quantity,
  metric,
  label,
}: {
  quantity: number | string | null;
  metric?: string;
  label: string;
}) => (
  <div>
    <div style={styles.cardTitle}>
      {quantity} <span style={styles.cardTitleDetail}>{metric}</span>
    </div>
    <p style={styles.cardContent}>{label}</p>
  </div>
);

const DashboardSchoolAdmin: FC = () =>{
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { currentSchool } = useSelector((state: RootState) => state.main);
  const [waitDailyAverage, setWaitDailyAverage] = useState<string | null>(null);
  const [waitDailyAverageLoading, setWaitDailyAverageLoading] =
    useState<boolean>(true);
  const [waitAverage, setWaitAverage] = useState<string | null>(null);
  const [waitAverageLoading, setWaitAverageLoading] = useState<boolean>(true);

  const [averageNumberOfPasses, setAverageNumberOfPasses] = useState<number>(0);
  const [averageNumberOfPassesLoading, setAverageNumberOfPassesLoading] =
    useState<boolean>(true);

  const [classRoomEfficiencyAverage, setClassRoomEfficiencyAverage] =
    useState<number>(0);

  const [absentStudentsBySchool, setAbsentStudentsBySchool] =
    useState<number>(0);

  const [
    classRoomEfficiencyAverageLoading,
    setClassRoomEfficiencyAverageLoading,
  ] = useState<boolean>(true);

  const [topPassLocations, setTopPassLocations] = useState<any>([]);
  const [topPassLocationsLoading, setTopPassLocationsLoading] =
    useState<boolean>(true);

  const [averagePassDuration, setAveragePassDuration] = useState<number>(0);
  const [averagePassDurationLoading, setAveragePassDurationLoading] =
    useState<boolean>(true);

  const [absentStudentsLoading, setAbsentStudentsLoading] =
    useState<boolean>(true);

  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("sm"));

  useWebSocket([
    {
      entity: "attendance",
      method: "UPDATE",
      onMessageReceived: () => {
        fetchAbsentStudents();
      },
    },
    {
      entity: "hallPassRequest",
      method: "UPDATE",
      onMessageReceived: () => {
        fetchAveragePassDuration();
      },
    },
  ]);

  useEffect(() => {
    if (!currentSchool?.id) return;

    dispatch(fetchStudentsWithActivePassBySchoolId(currentSchool.id));

    /* #region get Average students in line */
    setWaitDailyAverage("0");
    setWaitDailyAverageLoading(true);
    const fetchData = async () => {
      if (!currentSchool?.id) return;
      return await getWaitDailyAverage(currentSchool.id);
    };
    fetchData()
      .then((value) => {
        if (value && isNaN(value) && value[0].avg) {
          setWaitDailyAverage(Math.round(value[0].avg as number).toString());
          setWaitDailyAverageLoading(false);
        } else {
          setWaitDailyAverage("0");
          setWaitDailyAverageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setWaitDailyAverageLoading(false);
      });
    /* #endregion */

    /* #region get Average Number Of Passes */
    setAverageNumberOfPasses(0);
    setAverageNumberOfPassesLoading(true);
    getAverageNumberOfPasses(currentSchool.id)
      .then((result) => {
        const averagePasses = isNaN(result) ? 0 : Number(result.toPrecision(2));
        setAverageNumberOfPasses(averagePasses);
      })
      .finally(() => {
        setAverageNumberOfPassesLoading(false);
      });
    /* #endregion */

    /* #region get ClassRoom Efficiency Average  */
    setClassRoomEfficiencyAverage(0);
    setClassRoomEfficiencyAverageLoading(true);
    getClassRoomEfficiencyAverage(currentSchool.id)
      .then((result) => {
        console.log(result)
        setClassRoomEfficiencyAverage(result);
      })
      .finally(() => {
        setClassRoomEfficiencyAverageLoading(false);
      });
    /* #endregion */

    /* #average time spent in queue */
    setWaitAverage("0");
    setWaitAverageLoading(true);
    getWaitAverage(currentSchool.id)
      .then((value) => {
        if (value && isNaN(value) && value[0].avg) {
          setWaitAverage(Math.round(value[0].avg as number).toString());
          setWaitAverageLoading(false);
        } else {
          setWaitAverage("0");
          setWaitAverageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setWaitAverageLoading(false);
      });
    /* #endregion */

    /* #average Top pass locations */
    setTopPassLocations(0);
    setTopPassLocationsLoading(true);
    getTopPassLocationsStatistic(currentSchool.id)
      .then((value) => {
        setTopPassLocations(value);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTopPassLocationsLoading(false);
      });
    /* #endregion */
    fetchAveragePassDuration();

    fetchAbsentStudents();
  }, [currentSchool]);

  const fetchAveragePassDuration = () => {
    if (!currentSchool?.id) return;
    setAveragePassDurationLoading(true);
    getAveragePassDurationInMinutes(currentSchool.id)
      .then((result) => {
        setAveragePassDuration(Number(result?.toFixed(1) || 0));
      })
      .finally(() => {
        setAveragePassDurationLoading(false);
      });
  };

  const fetchAbsentStudents = () => {
    if (!currentSchool.id) return;

    /* #region get ClassRoom Efficiency Average  */

    setAbsentStudentsBySchool(0);
    setAbsentStudentsLoading(true);
    getAbsentsBySchoolId(
      currentSchool.id,
      moment(new Date()).format("YYYY-MM-DD")
    )
      .then((countAbsentStudents) => {
        setAbsentStudentsBySchool(Number(countAbsentStudents.length));
      })
      .finally(() => {
        setAbsentStudentsLoading(false);
      });
    /* #endregion */
  };

  const handleOnClickAbsentBox = (event: React.SyntheticEvent) => {
    event.preventDefault();
    // 👇️ redirect to /Absents
    navigate("/Absents");
  };

  const handleOnClickUtilizationtBox = (event: React.SyntheticEvent) => {
    event.preventDefault();
    // 👇️ redirect to /Absents
    navigate("/ClassTimeUtilization");
  };

  return (
    <>
      {!downMd ? <div style={{ marginTop: "-80px" }}></div> : <div></div>}

      <div>
        <Grid container textAlign="start" rowSpacing={2} marginBottom={2}>
          <Grid item xs={12}>
            <Typography fontWeight={500} fontSize="1.25em">
              Dashboard
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ActiveHallPassesCard />
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={500}>Semester Statistics</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Item>
              {averageNumberOfPassesLoading ? (
                <Loading />
              ) : (
                <StatisticCard
                  quantity={averageNumberOfPasses}
                  label="Average # of Passes"
                />
              )}
              <div></div>
            </Item>
          </Grid>
          <Grid item xs={6} md={4}>
            <Item>
              {waitDailyAverageLoading ? (
                <Loading />
              ) : (
                <StatisticCard
                  quantity={waitDailyAverage}
                  label="Average # of students waiting for pass"
                />
              )}
            </Item>
          </Grid>
          <Grid item xs={6} md={4}>
            <Item sx={{ p: 0 }}>
              {absentStudentsLoading ? (
                <Loading />
              ) : (
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={handleOnClickAbsentBox}
                >
                  <Box flex={1}>
                    <StatisticCard
                      quantity={
                        absentStudentsBySchool ? absentStudentsBySchool : 0
                      }
                      label="Absent Students"
                    />
                  </Box>
                  <ChevronRightOutlined sx={{ fill: styles.cardTitle.color }} />
                </Box>
              )}
            </Item>
          </Grid>
          {/* Class Time utilization starts here */}
          <Grid item xs={6} md={4}>
            <Item sx={{ p: 0 }}>
              {classRoomEfficiencyAverageLoading ? (
                <Loading />
              ) : (
                <Box
                 
                  sx={{
                    cursor: "pointer",
                    textAlign: "center", // Center text
                    display: "flex",
                    alignItems: "center", // Center content vertically
                    justifyContent: "flex-end", // Center content horizontally
                    paddingLeft: '41px',
                  }}
                  width="100%"
                  onClick={handleOnClickUtilizationtBox}
                >
                  <Box flex={1} >
                    <StatisticCard

                      quantity={classRoomEfficiencyAverage}
                      metric="%"
                      label="Class Time Utilization"
                    />
                  </Box>
                  <ChevronRightOutlined sx={{ fill: styles.cardTitle.color }} />
                </Box>

              )}
            </Item>
          </Grid>
          <Grid item xs={6} md={4}>
            <Item>
              {averagePassDurationLoading ? (
                <Loading />
              ) : (
                <StatisticCard
                  quantity={Math.round(averagePassDuration)}
                  metric="min"
                  label="Average Pass Duration"
                />
              )}
            </Item>
          </Grid>
          <Grid item xs={6} md={4}>
            <Item>
              {waitAverageLoading ? (
                <>Loading...</>
              ) : (
                <StatisticCard
                  quantity={waitAverage}
                  metric="min"
                  label="Average Passes Waiting time"
                />
              )}
            </Item>
          </Grid>
        </Grid>

        <Grid
          container
          textAlign="start"
          rowSpacing={2}
          marginTop={2}
          marginBottom={2}
        >
          <Grid item xs={12}>
            <Typography fontWeight={500}>Top Pass Locations</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {topPassLocationsLoading && (
                <LinearProgress sx={{ width: "100%" }}></LinearProgress>
              )}
              <Table sx={{ background: "#3C404E" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Location</TableCell>
                    <TableCell align="right">Number</TableCell>
                    <TableCell align="right">Avg.&nbsp;Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!topPassLocationsLoading &&
                    topPassLocations.map((row: any) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.locationName}
                        </TableCell>
                        <TableCell align="right">{row.number}</TableCell>
                        <TableCell align="right">
                          {`${row.avgDuration?.toFixed(2)} mins`}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DashboardSchoolAdmin;
