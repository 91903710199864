import {
  fetchActiveSemestersBySchoolId, fetchAllSemestersBySchoolId,
} from "./../../Services/SemestersService";
import { createSlice } from "@reduxjs/toolkit";
import { Semester } from "../../Interfaces/SemesterInterface";
import {
  createSemester,
  deleteSemester,
  fetchSemestersBySchoolId,
  updateSemester,
} from "../../Services/SemestersService";
import { User } from "../../Interfaces/UserInterface";

export const semesterSlice = createSlice({
  name: "semester",
  initialState: {
    semesters: [] as Semester[],
    allSemesters: [] as Semester[],
    loadingIndicator: false,
    savingIndicator: false,
    selectedSemester: {} as Semester | undefined,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setSelectedSemester: (state, { payload }) => {
      state.selectedSemester = payload;
    },
  },
  extraReducers: (builder) => {
    //fetch semesters
    builder.addCase(fetchSemestersBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchSemestersBySchoolId.fulfilled,
        (state, { payload }) => {
          state.semesters = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchSemestersBySchoolId.rejected, (state) => {
        state.loadingIndicator = false;
      });

    // fetch active semesters
    builder.addCase(fetchActiveSemestersBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchActiveSemestersBySchoolId.fulfilled,
        (state, { payload }) => {
          state.semesters = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchActiveSemestersBySchoolId.rejected, (state) => {
        state.loadingIndicator = false;
      });

    // fetch all semesters
    builder.addCase(fetchAllSemestersBySchoolId.pending, (state) => {
      state.loadingIndicator = true;
    }),
      builder.addCase(
        fetchAllSemestersBySchoolId.fulfilled,
        (state, { payload }) => {
          state.allSemesters = payload;
          state.loadingIndicator = false;
        }
      ),
      builder.addCase(fetchAllSemestersBySchoolId.rejected, (state) => {
        state.loadingIndicator = false;
      });

    // create semesters
    builder.addCase(createSemester.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(createSemester.fulfilled, (state, { payload }) => {
        state.semesters.push(payload);
        state.successMessage = "Semester created successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(createSemester.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // update semesters

    builder.addCase(updateSemester.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(updateSemester.fulfilled, (state, { payload }) => {
        const index = state.semesters.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          state.semesters[index] = payload;
        }
        state.successMessage = "Semester updated successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(updateSemester.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });

    // delete semester

    builder.addCase(deleteSemester.pending, (state) => {
      state.savingIndicator = true;
    }),
      builder.addCase(deleteSemester.fulfilled, (state, { payload }) => {
        const index = state.semesters.findIndex((s) => s.id === payload.id);
        if (index > -1) {
          state.semesters.splice(index, 1);
        }
        state.successMessage = "Semester removed successfully";
        state.savingIndicator = false;
      }),
      builder.addCase(deleteSemester.rejected, (state, { error }) => {
        state.errorMessage = `${error.message}`;
        state.savingIndicator = false;
      });
  },
});

export default semesterSlice.reducer;
export const { setSuccessMessage, setErrorMessage, setSelectedSemester } =
  semesterSlice.actions;
